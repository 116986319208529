// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "vendor/bootstrap.bundle.min.js"; // Loads Bootstrap 5.3
import "vendor/purecounterjs/dist/purecounter_vanilla.js";
import "vendor/flatpickr.min.js";

// import { Choices } from "choices.js/public/assets/scripts/choices.min.js";
import "vendor/choices.min.js";

import { tns } from 'tiny-slider/src/tiny-slider'
window.tns = tns

// import noUiSlider from 'nouislider';
import noUiSlider from './vendor/nouislider.min.js';
window.noUiSlider = noUiSlider;

require("./public/functions.js");
// import "./public/functions.js";
// import dayjs from 'dayjs';
// import "../vendor/sticky.min.js";
import "./show.js";

import lazysizes from 'lazysizes';

import "./public/home.js";

import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = false
