import dayjs from 'dayjs';

$(document).on("turbo:load", function() {
    // new Blazy(
    //   {
    //     src: 'data-lazysrc',
    //     breakpoints: [
    //       {
    //         width: 420,
    //         src: 'data-lazysrcsm'
    //       }
    //     ],
    //     success: (element) => {
    //       setTimeout(() => {
    //         let parent = element.parentNode
    //         parent.className = parent.className.replace(/\bloading\b/, '')
    //       }, 300)
    //     }
    //   }
    // )
  
    $('.home-checkin').on('change', function (e) {
      let hm_checkin = $(e.target).val()
      let hm_checkout = $('.home-checkout').val()
      hm_checkin = new Date(hm_checkin)
      if(hm_checkout == '' || (hm_checkout != '' && hm_checkin >= new Date(hm_checkout))) {
        $('.home-checkout').val(dayjs(hm_checkin).add(1, 'days').format('YYYY-MM-DD'))
        $('.home-checkout').flatpickr('update', dayjs(hm_checkin).add(1, 'days').format('YYYY-MM-DD'))
        // $('.home-checkout').trigger('change')
      }
    })
  
    $('.home-checkout').on('change', function (e) {
      let checkout = $(e.target).val()
      let checkin = $('.home-checkin').val()
      if(new Date(checkout) <= new Date(checkin) || checkin == '') {
        $('.home-checkin').val(dayjs(checkout).subtract(1,'days').format('YYYY-MM-DD'))
        $('.home-checkin').flatpickr('update', dayjs(checkout).subtract(1,'days').format('YYYY-MM-DD'))
      }
    })

    $('#submit-contact').on('click', () => {
      gtag('event', 'contact_sent', {
        'event_category' : 'contact'
      });
    });
  
    $('#submit-owner-contact').on('click', () => {
      gtag('event', 'owner_contact_sent', {
        'event_category' : 'ownercontact'
      });
    });  
  
  })